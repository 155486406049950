import { signIn, signOut, useSession } from 'next-auth/react';
import { useUserContext } from 'contexts/UserContext';
import { userLogout } from 'helpers/Logout';
import { useRouter } from 'next/router';
import axios from 'axios';
import { fireToast } from 'helpers/Toasts';
import { randomStringGenerator } from 'helpers/randomStringGenerator';
import { generateDDRecommendedUserCookie } from 'utils/ddRecommendedUserCookie';
import { User } from 'types';
import { formatServerError } from 'helpers/logger';
import { API } from 'helpers/pages';

export const keycloakLogout = async (callbackUrl?: string) => {
  try {
    await axios.get('/api/auth/keycloak_signout');
    await signOut(callbackUrl ? { callbackUrl } : { redirect: false });
  } catch (error) {
    console.error(
      formatServerError({
        message: 'Unable to signout',
        error,
        route: API.KEYCLOAK_SIGNOUT,
      }),
    );
  }
};

const useAuthOptions = () => {
  const {
    user,
    dispatchUser,
    consentOptions: { functionalConsent },
  } = useUserContext();
  const { data: session, status } = useSession();
  const router = useRouter();
  const asPath = router.asPath;
  const username = user?.name || '';
  const isLoggedInKeycloakUser = !!session?.user;
  const shouldReturnAccessToken = !!user && !!session?.user && !session?.error;

  const handleLogin = (url?: string) => {
    signIn('keycloak', {
      callbackUrl:
        typeof url === 'string'
          ? `/signin?callbackUrl=${url}`
          : `/signin?callbackUrl=${asPath}`,
    });
  };

  const handleLogout = async (callbackUrl?: string, user?: User) => {
    try {
      await keycloakLogout(callbackUrl);
    } catch (error) {
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
      return;
    }
    if (functionalConsent) {
      document.cookie = generateDDRecommendedUserCookie(
        randomStringGenerator(),
      );
    }

    // If the user is a dealer we don't want them log them out
    if (!user || user?.type !== 'DEALER') {
      await userLogout(dispatchUser, username);
    }
  };

  return {
    handleLogin,
    handleLogout,
    isAuthenticated: isLoggedInKeycloakUser,
    accessToken: shouldReturnAccessToken ? session?.accessToken : undefined,
    status,
  };
};

export { useAuthOptions };
