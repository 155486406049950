import App, { AppInitialProps } from 'next/app';
import Head from 'next/head';
import type { AppProps as NextAppProps, AppContext } from 'next/app';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { DidomiSDK } from '@didomi/react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import StatusCode from 'status-code-enum';

import Script from 'next/script';
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { DefaultSeo, LocalBusinessJsonLd } from 'next-seo';
import getConfig from 'next/config';
import 'helpers/Grid/Grid.scss';
import { SessionProvider } from 'next-auth/react';

import { DISPLAY_NAVIGATION_MENU, getServerCookie } from 'utils';
import type { UserCookieInfo, User } from 'types';
import { GlobalStyles } from 'helpers/GlobalStylesHelper';
import { theme } from 'helpers/Theme/';
import { UserProvider } from 'contexts/UserContext';
import { GlobalProvider } from 'contexts/GlobalContext';
import { getTLD } from 'helpers/domain';
import { randomStringGenerator } from 'helpers/randomStringGenerator';
import 'react-toastify/dist/ReactToastify.css';
import { pushToDataLayer } from 'services/gtmService';
import { Session } from 'next-auth';
import { formatServerError } from 'helpers/logger';
import { PAGE } from 'helpers/pages';
import RefreshTokenHandler from 'components/_App/RefreshAccessTokenHandler';
import { useDidomi } from 'hooks/UseDidomi';
import { useRaygun } from 'hooks/UseRaygun';
import { PURPOSES } from 'types/didomi';
import { getConsent } from 'utils/GetConsent';
import { useOnNetworkConnectionStatusChange } from 'hooks/UseOnNetworkConnectionStatusChange';
import { AppConsumer } from 'components/_App/_App';
import { Navigation } from 'domains/Navigation';
import { frontendServerGateway } from 'api/api-utils/commonApiUtils';
import { formatCookie, getDomainFromBaseURL } from 'utils/CookieUtils';
import { COOKIE, MAXIMUM_COOKIE_AGE } from 'helpers/constants';
import { fireToast } from 'helpers/Toasts';
import { rg4js } from 'helpers/raygun';
import { isAxiosError } from 'axios';

// Fixed from Next v12.3.0 onwards
/** *NOTE*
 * The props returned to the client side component of _app are a combination of `MyApp.getInitialProps`
 * below as well as `getServerSideProps` returned from specific pages. For this reason props with
 * the same key can potentially overwrite each other leading to issues.
 */
type AppProps<P = any> = {
  pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

type PageProps = {
  // this is legacy user (cookie user info)
  userDD: User | undefined;
  session?: Session;
  domain: string;
  ddRecommendedUserValue: string | number | undefined;
  analyticsConsent: boolean;
  navigation: Navigation[];
  initialFunctionalConsent?: boolean;
};

if (typeof window !== 'undefined' && !window.IntersectionObserver) {
  require('intersection-observer');
}
const {
  publicRuntimeConfig: {
    BASE_URL,
    CDN_STATIC_ASSETS,
    ENVIRONMENT_NAME,
    GOOGLE_RECAPTCHA,
    DIDOMI_API_KEY,
    DIDOMI_NOTICE_ID,
    RAYGUN_API_KEY,
    INTEGRATION_TESTING,
  },
} = getConfig();
Router.events.on('routeChangeComplete', (url: string, { shallow }) => {
  if (!shallow) {
    pushToDataLayer({ url, event: 'page_view' });
  }
});
const DynamicToast = dynamic(() =>
  import(/* webpackChunkName: "Toast" */ 'components/Toolkit/Toast/Toast').then(
    (mod) => mod.Toast,
  ),
);

function MyApp({ Component, pageProps }: AppProps<PageProps>) {
  const {
    domain,
    ddRecommendedUserValue,
    userDD,
    session,
    navigation,
    initialFunctionalConsent = false,
  } = pageProps;
  const {
    didomiConfig,
    onReady,
    onConsentChanged,
    functionalConsent,
    allowRaygun,
    allowGoogle,
    allowWootric,
    allowBraze,
    allowConvert,
  } = useDidomi({ initialFunctionalConsent });
  const { debugMode, enablePulse } = useRaygun(allowRaygun, 30);

  const [refetchInterval, setRefetchInterval] = useState(0);

  useOnNetworkConnectionStatusChange();

  const initRaygunScript = () => {
    return {
      __html: `
        !function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
        (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
        f.async=1,f.crossorigin='anonymous',f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
        h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
        e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");

        rg4js('apiKey', '${RAYGUN_API_KEY}');
        rg4js('enableCrashReporting', true);
        rg4js('enablePulse', ${enablePulse});

        rg4js('options', {
          debugMode: ${debugMode},
          ignore3rdPartyErrors: true
        });
                
        rg4js('whitelistCrossOriginDomains', ['statics.donedeal.ie', 'statics.donedealtest.com', 'google.com', 'donedeal.ie', 'donedealtest.com', 'donedeal-frontend-pr-*.dsch.build', 'donedeal']);
                
         rg4js('onBeforeSend', function (payload) {
            let updatedPayload = payload;
            if (payload.Details?.Error?.Message == 'Script error.') {
              const googleAdsUrls = ["doubleclick.net", "googlesyndication.com", "adsensecustomsearchads.com"];
              if (!!payload.Details.Error.StackTrace) {
                if (payload.Details.Error.StackTrace.some(frame => googleAdsUrls.some(url => frame.FileName.includes(url)))) {
                  updatedPayload.Details.Error.Message = "An error occurred while loading an ad.";
                }
              }
            }

            if (payload.Details?.Error?.Message.includes('Loading chunk')) {
              updatedPayload.Details.Error.Message = "Loading chunk error";
            }
            return updatedPayload;
        });
        `,
    };
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => {
            const noToast = query.meta?.noToast;
            if (!noToast) {
              fireToast({
                text:
                  query.meta?.errorMessage ||
                  'Oops! Something went wrong, please try again later',
                type: 'ERROR',
              });
            }
            rg4js('send', {
              error,
              tags: query?.meta?.tags ? query.meta.tags : undefined,
            });
          },
        }),
        mutationCache: new MutationCache({
          onError: (error, _variables, _context, mutation) => {
            /**
             * A 422 response is valid and generally should be handled at the point of calling.
             * We do not necessarily want to fire an error toast or log to raygun.
             */
            if (
              isAxiosError(error) &&
              error.response?.status ===
                StatusCode.ClientErrorUnprocessableEntity
            ) {
              return;
            }
            const noToast = mutation.meta?.noToast;
            if (!noToast) {
              fireToast({
                text:
                  mutation.meta?.errorMessage ||
                  'Oops! Something went wrong, please try again later.',
                type: 'ERROR',
              });
            }
            rg4js('send', {
              error,
              tags: mutation?.meta?.tags ? mutation.meta.tags : undefined,
            });
          },
          onSuccess: (_, __, ___, mutation) => {
            if (mutation.meta?.successMessage) {
              fireToast({
                text: mutation.meta.successMessage,
                type: 'SUCCESS',
              });
            }
          },
        }),
      }),
  );
  return (
    <SessionProvider session={session} refetchInterval={refetchInterval}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalProvider navigation={navigation}>
            <UserProvider
              initialUser={userDD}
              ddRecommendedUserValue={ddRecommendedUserValue}
              consentOptions={{
                functionalConsent: functionalConsent,
                allowGoogle,
                allowWootric,
                allowBraze,
              }}
            >
              <DefaultSeo
                openGraph={{
                  type: 'website',
                  locale: 'en_IE',
                  url: domain,
                  site_name: 'DoneDeal.ie',
                  title:
                    "DoneDeal | Ireland's Largest Motor & Classifieds Marketplace",
                  description:
                    "Buy & Sell on Ireland's Largest Motor & Classifieds Marketplace, where more than 3,000,000 daily visitors come to Discover New & Used Cars, Other Motor, Farming and day to day items.",
                  images: [
                    {
                      url: `${CDN_STATIC_ASSETS}/images/meta/social-d-placeholder.jpg`,
                      width: 2000,
                      height: 1000,
                    },
                  ],
                }}
                twitter={{
                  site: '@DoneDeal',
                  cardType: 'app',
                }}
                title="DoneDeal | Ireland's Largest Motor & Classifieds Marketplace"
                description="Buy & Sell on Ireland's Largest Motor & Classifieds Marketplace, where more than 3,000,000 daily visitors come to Discover New & Used Cars, Other Motor, Farming and day to day items."
              />

              <Head>
                <meta
                  name="viewport"
                  content="initial-scale=1.0, width=device-width"
                />
                <meta name="apple-itunes-app" content="app-id=428330596" />
                <meta name="google" content="nositelinkssearchbox" />
                <meta property="fb:app_id" content="553023201403588" />
                <meta
                  name="facebook-domain-verification"
                  content="mkpjnrsx4etl2yzu4hv19ie94j808l"
                />
                <link
                  rel="icon"
                  href={`${CDN_STATIC_ASSETS}/favicons/favicon.svg`}
                />
                <link
                  rel="shortcut icon"
                  href={`${CDN_STATIC_ASSETS}/favicons/favicon.svg`}
                ></link>

                {/* Fallback  */}
                <link
                  rel="shortcut icon"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-48x48.png`}
                ></link>

                <link
                  rel="mask-icon"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-48x48.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="57x57"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-57x57.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="60x60"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-60x60.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="72x72"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-72x72.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="76x76"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-76x76.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="114x114"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-114x114.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="120x120"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-120x120.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="144x144"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-144x144.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="152x152"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-152x152.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-180x180.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="144x144"
                  href={`${CDN_STATIC_ASSETS}/favicons/favicon.svg`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="48x48"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-48x48.png`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="96x96"
                  href={`${CDN_STATIC_ASSETS}/favicons/donedeal-favicon-96x96.png`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="192x192"
                  href={`${CDN_STATIC_ASSETS}/favicons/android-icon-192x192.png`}
                />
              </Head>
              {!INTEGRATION_TESTING && (
                <DidomiSDK
                  apiKey={DIDOMI_API_KEY}
                  noticeId={DIDOMI_NOTICE_ID}
                  iabVersion={2}
                  gdprAppliesGlobally
                  config={didomiConfig}
                  onReady={onReady}
                  onConsentChanged={onConsentChanged}
                />
              )}
              <script
                src={`https://www.google.com/recaptcha/enterprise.js?render=${GOOGLE_RECAPTCHA}`}
                async
              />
              <Script
                id="raygun"
                dangerouslySetInnerHTML={initRaygunScript()}
                crossOrigin={'anonymous'}
              />

              {allowConvert && (
                <Script
                  async
                  type="text/javascript"
                  src="//cdn-4.convertexperiments.com/js/10042404-10043067.js"
                  crossOrigin="anonymous"
                />
              )}

              <LocalBusinessJsonLd
                type="Organization"
                id={domain}
                name="DoneDeal.ie"
                description="Buy & Sell on Ireland's Largest Motor & Classifieds Marketplace, where more than 3,000,000 daily visitors come to Discover New & Used Cars, Other Motor, Farming and day to day items."
                url={domain}
                address={{
                  streetAddress: 'DoneDeal.ie, Crescent Quay',
                  addressLocality: 'Wexford',
                  addressRegion: 'Wexford',
                  postalCode: 'Y35HE67',
                  addressCountry: 'IE',
                }}
                sameAs={[
                  'https://www.facebook.com/DoneDealIreland',
                  'https://twitter.com/donedeal',
                  'https://www.blog.donedeal.ie',
                  'https://www.youtube.com/user/DoneDealers',
                ]}
              />
              <GlobalStyles cdnUrl={CDN_STATIC_ASSETS} />
              <Component {...pageProps} domain={domain} />
              <AppConsumer domain={domain} />
              <RefreshTokenHandler setRefetchInterval={setRefetchInterval} />
              <DynamicToast />
              {/* Devtools is automatically stripped out in a production build */}
              <ReactQueryDevtools initialIsOpen={false} />
            </UserProvider>
          </GlobalProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}
/**
 * By adding getInitialProps here we are opting the entire app out of automatic static optimization.
 * For now this is acceptable, we will revisit this when the need arises.
 */
MyApp.getInitialProps = async (
  appContext: AppContext,
): Promise<AppInitialProps> => {
  let pageProps: AppInitialProps;
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  pageProps = await App.getInitialProps(appContext);
  const tld = getTLD(appContext.ctx.req);
  const domain = `${BASE_URL}${tld}`;

  let userCookie: UserCookieInfo | undefined = getServerCookie(
    'DDUS',
    appContext.ctx,
    true,
  );
  const didomiToken: string | undefined = getServerCookie(
    'didomi_token',
    appContext.ctx,
    true,
  );

  let navigation: Navigation[] = [];

  const displayNavigationMenu =
    DISPLAY_NAVIGATION_MENU && DISPLAY_NAVIGATION_MENU === 'true';
  const host = domain.split('//');
  const navigationDomain = host.length > 0 && host[1] ? host[1] : null;

  if (displayNavigationMenu && navigationDomain) {
    try {
      const navigationResponse = await frontendServerGateway.get(
        `/navigation`,
        {
          data: {
            domain: navigationDomain,
          },
        },
      );
      if (navigationResponse.status === 200) {
        navigation = navigationResponse.data;
      }
    } catch (error) {
      console.error(
        formatServerError({
          message: 'Error getting navigation data',
          route: PAGE.APP,
          error,
        }),
      );
    }
  }

  const functionalConsent = didomiToken
    ? getConsent({
        token: didomiToken,
        purpose: PURPOSES.FUNCTIONAL,
        onError: () =>
          console.error(
            formatServerError({
              message: 'Failed to decode Didomi token',
              route: PAGE.APP,
            }),
          ),
      })
    : false;

  // replace + symbol with spaces, if any, and use part of name that appears before the first space
  userCookie = userCookie
    ? {
        ...userCookie,
        name: userCookie?.name?.replace('+', ' ').split(' ')[0],
      }
    : undefined;

  function ddRecommendedUserValueGenerator(userId?: number) {
    if (functionalConsent) {
      const cookie = getServerCookie('ddRecommendedUser', appContext.ctx, true);

      return cookie && cookie !== 'undefined'
        ? cookie
        : userId ?? randomStringGenerator();
    } else return undefined;
  }

  const ddRecommendedUserValue = ddRecommendedUserValueGenerator(
    userCookie?.id,
  );
  const userDD = (userCookie?.name && userCookie) || undefined;

  const sessionID: string | undefined = getServerCookie(
    COOKIE.DD_CLIENT_ID,
    appContext.ctx,
    true,
  );

  if (!sessionID && functionalConsent) {
    const newSessionID = formatCookie(
      COOKIE.DD_CLIENT_ID,
      randomStringGenerator(),
      {
        domain: getDomainFromBaseURL(domain),
        maxAge: MAXIMUM_COOKIE_AGE,
        path: '/',
        httpOnly: false,
      },
    );
    appContext.ctx.res?.setHeader('set-cookie', newSessionID);
  }

  // TODO: Fix updatedUser not being undefined if no user
  return {
    pageProps: {
      ...pageProps,
      userDD,
      domain,
      ddRecommendedUserValue,
      navigation,
      initialFunctionalConsent: functionalConsent || false,
    },
  };
};

// Limit reporting to % of users (cost issue)
const rateLimit = Math.random() >= 0.5;
export function reportWebVitals(metric: any) {
  if (ENVIRONMENT_NAME === 'production' && rateLimit) {
    const { id, name, startTime, value, label } = metric;
    pushToDataLayer({
      event: `web-vitals`,
      event_category:
        label === 'web-vital' ? 'Web Vitals' : `Next.js custom metric`,
      event_action: name,
      nonInteraction: true, // avoids affecting bounce rate.
      event_startTime: startTime,
      event_value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers,
      event_label: id,
    });
  }
}
export default MyApp;
