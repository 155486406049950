import styled from 'styled-components';
import {
  InformationCircleOutlineIc,
  AlertCircleOutlineIc,
  CheckmarkCircleOutlineIc,
  CloseOutlineIc,
} from '@dsch/dd-icons';
import { spacing } from 'helpers/genericStyles';
import {
  Intent,
  containerColorSet,
  iconColorSet,
} from 'components/Toolkit/Notification/Notification.typed';

type IntentProp = { intent: Intent };
type ContainerProps = Pick<React.CSSProperties, 'alignItems'> & IntentProp;

export const Container = styled.div<ContainerProps>`
  ${spacing};
  padding: ${({ theme }) => theme.spacing.S12};

  background: ${({ theme, intent }) => theme.colors[containerColorSet[intent]]};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  position: relative;
`;

export const IconContainer = styled.div<IntentProp>`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.S8};
  color: ${({ theme, intent }) => theme.colors[iconColorSet[intent]]};
  line-height: ${({ theme }) => theme.spacing.M24};
`;

export const Title = styled.span`
  font-weight: bold;
  display: block;
  flex: 1;
`;

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  display: inline-block;
  ${({ theme }) => theme.fontSize.M14};
`;

export const CloseButton = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.S12};
  right: ${({ theme }) => theme.spacing.S12};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  cursor: pointer;
`;

export const SuccessIcon = styled(CheckmarkCircleOutlineIc)``;

export const ErrorIcon = styled(AlertCircleOutlineIc)``;

export const InformationIcon = styled(InformationCircleOutlineIc)``;

export const CloseIcon = styled(CloseOutlineIc)``;
