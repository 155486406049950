import getConfig from 'next/config';

export { getServerCookie } from './CookieUtils';

export const {
  publicRuntimeConfig: {
    CDN_STATIC_ASSETS,
    ENVIRONMENT_NAME,
    MIGRATED_PUBLISH_SECTIONS,
    BASE_URL,
    DISPLAY_NAVIGATION_MENU,
    API_CLIENT_TIMEOUT,
    DFP_PHOTOBROWSER_REFRESH_THRESHOLD,
    FEATURE_FEEDBACK,
    DISPLAY_CERTIFIED_TRUSTED_DEALER_CARS_FILTER,
    HUBVISOR_SCRIPT_ENABLED,
    FEATURE_USE_NEW_CONVERSATION_API,
    DISPLAY_AD_DETAILS_CAR_VALUATION_TOOL,
  },
} = getConfig();
