import type * as T from 'components/Toolkit/Notification/Notification.typed';
import * as Styled from 'components/Toolkit/Notification/Notification.styled';

const getIcon = (intent: T.Intent, iconOverride?: string) => {
  const props = { width: 24, height: 24 };
  if (!!iconOverride) {
    return <img src={iconOverride} alt="" {...props} />;
  }
  switch (intent) {
    case 'SUCCESS':
      return <Styled.SuccessIcon {...props} />;
    case 'ERROR':
      return <Styled.ErrorIcon {...props} />;
    case 'INFO':
      return <Styled.InformationIcon {...props} />;
    default:
  }
};

const Notification = (props: T.NotificationProps) => {
  const {
    intent,
    children,
    hasIcon = true,
    iconOverride,
    isClosable = false,
    onClose,
    className,
    alignItems = 'center',
    ...spaceProps
  } = props;
  return (
    <Styled.Container
      intent={intent}
      className={className}
      data-testid="notification"
      alignItems={alignItems}
      {...spaceProps}
    >
      {hasIcon && (
        <Styled.IconContainer intent={intent}>
          {getIcon(intent, iconOverride)}
        </Styled.IconContainer>
      )}
      <Styled.TextWrapper>{children}</Styled.TextWrapper>
      {isClosable && (
        <Styled.CloseButton data-testid="close-button" onClick={onClose}>
          <Styled.CloseIcon width="16" height="16" />
        </Styled.CloseButton>
      )}
    </Styled.Container>
  );
};

const Title = Styled.Title;
Notification.Title = Title;

export { Notification };
