import axios, { type AxiosError } from 'axios';
import { type PAGE, type API } from 'helpers/pages';

const cleanApiError = (axiosError: AxiosError) => ({
  api: {
    request: {
      url: axiosError?.config?.url,
      baseURL: axiosError?.config?.baseURL,
      method: axiosError?.config?.method,
    },
    response: axiosError?.response?.status
      ? {
          status: axiosError?.response?.status,
          statusText: axiosError?.response?.statusText,
          message: (axiosError?.response?.data as { message?: string })
            ?.message,
        }
      : null,
  },
  message: axiosError?.message || null,
});

type Payload = {
  message: string;
  route: PAGE | API;
  error?: Error;
};

/**
 * @param { Payload } payload - An object containing message, route, and error fields.
 * @param { string } payload.message A short summary of what action has failed.
 * Avoid repeating information contained in the route and the error fields, if an error is provided.
 * Ensure that it is easily searchable by making the message unique to the page it is found, including a reference to the service that has failed, if any.
 * @param { PAGE | API } payload.route  A descripion of the page on which the error occured, or the API.
 * The API should be used for calls that are not page-specific, such as signout, otherwise the name of the page should be used.
 * @param { Error } [payload.error] An error may not always be available, such as when data required for an API call is missing, preventing the call from being made. In this case, we throw an error with the provided message value.
 * @returns a formatted error, including axios errors, that may be logged with console.error
 */
export const formatServerError = (payload: Payload) => {
  let { message, error, route } = payload || {};

  if (!error) {
    try {
      throw new Error(message);
    } catch (e) {
      error = e as Error;
    }
  }
  if (axios.isAxiosError(error)) {
    return {
      route,
      ...cleanApiError(error),
    };
  } else {
    return {
      route,
      error,
      message,
    };
  }
};
